import { Box, Button, Divider, Typography } from "@mui/material";
import { DspCheckoutParameterGroupType } from "application/entities/dataTypes/dspEnablementOptions";
import { getCheckoutParameter, getCheckoutParameterGroup, getDspAdditionalInfo, getDspIcon } from "application/utils/dsp.utils";
import { providerIconList } from "assets/providerIcons";
import { AppIcon } from "components/ui/AppIcon";
import { AppTip } from "pages/AudienceBuilder/components/AudienceBuilderAppTip";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { StepDescription, StepTitle } from "..";
import { useAudienceEnablementStore } from "../store/AudienceEnablement.store";
import { OptionBoolean } from "./EnablementOptionsStep/OptionBoolean";
import { OptionDomains } from "./EnablementOptionsStep/OptionDomains";
import { OptionSelect } from "./EnablementOptionsStep/OptionSelect";
import { OptionSelectMultiple } from "./EnablementOptionsStep/OptionSelectMultiple";

export const EnablementOptionsStep = () => {
  const { t } = useTranslation();

  const actions = useAudienceEnablementStore((state) => state.actions);
  const accountId = useAudienceEnablementStore((state) => state.audienceData.account.id);

  const selectionStepForm = useAudienceEnablementStore((state) => state.selectionStepForm);

  type OptionsFormattedType = {
    activationPlatformCode: string;
    activationPlatform: string;
    ssp: {
      code: string;
      name: string;
      dspCheckoutParameterGroups: DspCheckoutParameterGroupType[];
    }[];
  };

  const optionsFormatted: OptionsFormattedType[] = [];

  selectionStepForm
    .filter((item) => item.profiles > 0 && item.dspCheckoutParameterGroups.length > 0)
    .forEach((item) => {
      if (optionsFormatted.findIndex((option) => option.activationPlatformCode === item.activationPlatformCode) === -1) {
        optionsFormatted.push({
          activationPlatformCode: item.activationPlatformCode,
          activationPlatform: item.activationPlatform,
          ssp: [
            {
              code: item.code,
              name: item.name,
              dspCheckoutParameterGroups: item.dspCheckoutParameterGroups,
            },
          ],
        });
      } else {
        const index = optionsFormatted.findIndex((option) => option.activationPlatformCode === item.activationPlatformCode);
        optionsFormatted[index].ssp.push({
          code: item.code,
          name: item.name,
          dspCheckoutParameterGroups: item.dspCheckoutParameterGroups,
        });
      }
    });

  const updateCheckoutParameters = (
    sspCode: string,
    dspCheckoutParameterGroupCode: string,
    checkoutParameterCode: string,
    value: boolean | string | string[]
  ) => {
    actions.updateCheckoutParameters(sspCode, dspCheckoutParameterGroupCode, checkoutParameterCode, value);
  };

  const isValidStep = () => {
    let hasError = optionsFormatted
      .map((channel) => {
        let channelValid = channel.ssp
          .map((ssp) => {
            let sspHasError = ssp.dspCheckoutParameterGroups
              .map((group) => {
                const checkoutParmameterGroup = getCheckoutParameterGroup(group);
                return checkoutParmameterGroup.dspCheckoutParameters
                  .map((checkoutParam) => {
                    if (checkoutParmameterGroup.required && checkoutParam?.selected) {
                      return true;
                    } else if (!checkoutParmameterGroup.required) {
                      return true;
                    }
                    return false;
                  })
                  .includes(true);
              })
              .includes(false);
            return sspHasError;
          })
          .includes(false);
        return channelValid;
      })
      .includes(false);
    return !hasError;
  };

  const cleanSelectionAndGotoToNextStep = () => {
    //remove sizes if ad_format is not checked for native and video
    optionsFormatted.forEach((channel) => {
      channel.ssp.forEach((ssp) => {
        const formatBanner = ssp.dspCheckoutParameterGroups
          .find((group) => group.code === "ad_format_group")
          ?.dspCheckoutParameters.find((param) => param.code === "format_banner");
        const formatNative = ssp.dspCheckoutParameterGroups
          .find((group) => group.code === "ad_format_group")
          ?.dspCheckoutParameters.find((param) => param.code === "format_native");
        const formatVideo = ssp.dspCheckoutParameterGroups
          .find((group) => group.code === "ad_format_group")
          ?.dspCheckoutParameters.find((param) => param.code === "format_video");
        const adSizeBanner = ssp.dspCheckoutParameterGroups
          .find((group) => group.code === "ad_size_banner_group")
          ?.dspCheckoutParameters.find((param) => param.code === "ad_size_banner");
        const adSizeVideo = ssp.dspCheckoutParameterGroups
          .find((group) => group.code === "ad_size_video_group")
          ?.dspCheckoutParameters.find((param) => param.code === "ad_size_video");
        const adSizeNative = ssp.dspCheckoutParameterGroups
          .find((group) => group.code === "ad_size_native_group")
          ?.dspCheckoutParameters.find((param) => param.code === "ad_size_native");

        if (formatBanner?.selected === false && adSizeBanner?.selected) {
          updateCheckoutParameters(ssp.code, channel.activationPlatformCode, adSizeBanner.code, false);
        }
        if (formatNative?.selected === false && adSizeBanner?.selected) {
          updateCheckoutParameters(ssp.code, channel.activationPlatformCode, adSizeBanner.code, false);
        }
        if (formatVideo?.selected === false && adSizeVideo?.selected) {
          updateCheckoutParameters(ssp.code, channel.activationPlatformCode, adSizeVideo.code, false);
        }
        if (formatNative?.selected === false && adSizeNative?.selected) {
          updateCheckoutParameters(ssp.code, channel.activationPlatformCode, adSizeNative.code, false);
        }
      });
    });
    actions.goToNextStep();
  };

  return (
    <>
      <StepTitle>
        <span>Configure Audience</span>
        <Button variant="outlined" onClick={() => actions.goToPreviousStep()}>
          Back
        </Button>
      </StepTitle>
      <StepDescription>Configure the parameters for your audience enablement methods.</StepDescription>

      {optionsFormatted?.map((channel) => {
        return (
          <Box key={channel.activationPlatformCode} p={2} mb={4} sx={{ background: "#fafafa", borderRadius: "8px", border: "1px solid #ececec" }}>
            <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }} mb={1}>
              <AppIcon fontSize={"smallbis"} icon={providerIconList[channel.activationPlatformCode]} />
              <Typography variant="h5">{channel.activationPlatform}</Typography>
            </Box>
            <Divider sx={{ marginBottom: "1rem" }} />

            {channel.ssp.map((ssp) => {
              const additionnalInfo = getDspAdditionalInfo(ssp.code);
              return (
                <Box key={ssp.code} ml={3} mb={4}>
                  <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }} mb={2}>
                    <AppIcon className="dsp-logo" fontSize={"small"} icon={getDspIcon(ssp.code)} />
                    <Typography variant="h6">{additionnalInfo?.name ?? ssp.name} enablement options</Typography>
                  </Box>
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
                    {ssp.dspCheckoutParameterGroups.map((group) => {
                      const checkoutParmameterGroup = getCheckoutParameterGroup(group);
                      let formatBannerValue = ssp.dspCheckoutParameterGroups
                        .find((group) => group.code === "ad_format_group")
                        ?.dspCheckoutParameters.find((param) => param.code === "format_banner");
                      let formatNativeValue = ssp.dspCheckoutParameterGroups
                        .find((group) => group.code === "ad_format_group")
                        ?.dspCheckoutParameters.find((param) => param.code === "format_native");
                      let formatVideoValue = ssp.dspCheckoutParameterGroups
                        .find((group) => group.code === "ad_format_group")
                        ?.dspCheckoutParameters.find((param) => param.code === "format_video");

                      if (checkoutParmameterGroup.code === "ad_size_banner_group" && !formatBannerValue?.selected) return null;
                      if (checkoutParmameterGroup.code === "ad_size_video_group" && !formatVideoValue?.selected) return null;
                      if (checkoutParmameterGroup.code === "ad_size_native_group" && !formatNativeValue?.selected) return null;

                      let constraint: string | undefined = undefined;
                      //add a message for incompatibily Format native and ad format native for pubmatic
                      if (checkoutParmameterGroup.code === "viewability_group" && formatNativeValue?.selected && ssp.code.startsWith("pubmatic_")) {
                        constraint = "Note: Viewability will not be applied to Native formats";
                      }
                      //Exception for domains
                      if (checkoutParmameterGroup.code === "domains_group")
                        return (
                          <>
                            <OptionBox
                              key={group.code}
                              title={checkoutParmameterGroup.title}
                              description={checkoutParmameterGroup.description}
                              appTipContent={checkoutParmameterGroup.appTipContent}
                              required={checkoutParmameterGroup.required}
                            >
                              <OptionDomains
                                accountId={+accountId}
                                onChange={(code, value) => {
                                  updateCheckoutParameters(ssp.code, checkoutParmameterGroup.code, code, value);
                                  updateCheckoutParameters(
                                    ssp.code,
                                    checkoutParmameterGroup.code,
                                    code === "exclude_domains" ? "include_domains" : "exclude_domains",
                                    false
                                  );
                                }}
                                domainsGroupCheckoutParameters={checkoutParmameterGroup.dspCheckoutParameters}
                              />
                            </OptionBox>
                          </>
                        );
                      return (
                        <OptionBox
                          key={group.code}
                          title={checkoutParmameterGroup.title}
                          description={checkoutParmameterGroup.description}
                          appTipContent={checkoutParmameterGroup.appTipContent}
                          required={checkoutParmameterGroup.required}
                          nbColumns={checkoutParmameterGroup.code === "ad_platform_group" ? 2 : undefined}
                          constraint={constraint}
                        >
                          <Box sx={{ display: "flex", gap: "1rem", flexWrap: "wrap" }}>
                            {checkoutParmameterGroup.dspCheckoutParameters.map((checkoutParam) => {
                              return (
                                <Fragment key={checkoutParam.code}>
                                  {checkoutParam.type === "Boolean" && (
                                    <OptionBoolean
                                      onChange={(e) => updateCheckoutParameters(ssp.code, checkoutParmameterGroup.code, checkoutParam.code, e)}
                                      value={checkoutParam?.selected as boolean}
                                      label={checkoutParam.parameterName}
                                    />
                                  )}
                                  {checkoutParam.type === "ArrayString" && (
                                    <OptionSelectMultiple
                                      onChange={(e) => updateCheckoutParameters(ssp.code, checkoutParmameterGroup.code, checkoutParam.code, e)}
                                      options={getCheckoutParameter(ssp.code, checkoutParam.code)?.options}
                                      value={checkoutParam?.selected as string[]}
                                    />
                                  )}
                                  {checkoutParam.type === "String" && (
                                    <OptionSelect
                                      onChange={(e) => updateCheckoutParameters(ssp.code, checkoutParmameterGroup.code, checkoutParam.code, e)}
                                      options={getCheckoutParameter(ssp.code, checkoutParam.code)?.options}
                                      value={checkoutParam?.selected as string}
                                    />
                                  )}
                                </Fragment>
                              );
                            })}
                          </Box>
                        </OptionBox>
                      );
                    })}
                  </Box>
                </Box>
              );
            })}
          </Box>
        );
      })}

      <Box>
        <Typography variant="body1" className="color-tech-grey">
          <span style={{ color: "red" }}>*</span> This option is required
        </Typography>
      </Box>

      <Box textAlign={"center"} mt={2}>
        <Button disabled={!isValidStep()} onClick={cleanSelectionAndGotoToNextStep} variant={"contained"} value={"Continue"} data-cy={"continue-btn"}>
          {t("dsp.continue")}
        </Button>
      </Box>
    </>
  );
};

type OptionBoxProps = {
  title: string;
  description: string;
  children: JSX.Element;
  appTipContent?: string;
  required?: boolean;
  nbColumns?: number;
  constraint?: string;
};
const OptionBox = (props: OptionBoxProps) => {
  const width = props.nbColumns ? `calc(100% / ${3 / props.nbColumns} - 1rem)` : "calc(100% / 3 - 1rem)";
  return (
    <Box sx={{ border: "1px solid #ececec", padding: "1rem", background: "#fcfcfc", borderRadius: "7px", width: width }}>
      <Box mb={1} sx={{ display: "flex", justifyContent: "space-between" }}>
        <Typography variant="h6" sx={{ fontSize: "14px" }}>
          {props.title} {props.required && <span style={{ color: "red" }}>*</span>}
        </Typography>
        {props.appTipContent && <AppTip title={""} text={props.appTipContent} />}
      </Box>

      <Typography className="color-tech-grey" variant="body1" mb={2}>
        {props.description} <br />
        {props.constraint && <span style={{ fontWeight: 600, color: "#FC6050" }}>{props.constraint}</span>}
      </Typography>

      {props.children}
    </Box>
  );
};
