import { apiDataFactory, objectApi } from "application/entities/dataApi";
import { dataObject } from "application/entities/dataObjects/DataObjectClass";
import { AudienceTypesDto } from "application/entities/dataTypes/audienceTypes";
import { PageId } from "application/pages/pages.config";
import { pageUtils } from "application/pages/pages.utils";
import { dataUtils } from "application/utils/dataState.utils";
import React from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { useUiStore } from "redux/ui.store";

export const useCreateAudience = (accountId: number) => {
  const navigate = useNavigate();
  const qyAudienceType = useQuery("qyAudienceType", () => {
    return apiDataFactory("audienceTypes")
      .get()
      .then((res) => {
        return res?.data?._embedded?.audienceTypes;
      });
  });

  // const {currentBrand, currentCompany} = useUiStore((st) => st);
  // const accountId                    = currentBrand?.id ?? currentCompany?.id ?? AUTHUser.accountId;

  const [loadState, setloadState] = React.useState<PageId | undefined>(undefined);

  const createAudience = (pageId: PageId) => {
    setloadState(pageId);
    let travelId;
    let interestId;
    let standardId; //advanced
    let automotiveId;
    let firstPartyDataId;
    let cpgId;

    if (qyAudienceType.data) {
      travelId = dataUtils.getObjectByFieldValue<AudienceTypesDto>(qyAudienceType.data, "code", "TRAVEL")?.[0]?.id;

      interestId = dataUtils.getObjectByFieldValue<AudienceTypesDto>(qyAudienceType.data, "code", "INTEREST")?.[0]?.id;

      standardId = dataUtils.getObjectByFieldValue<AudienceTypesDto>(qyAudienceType.data, "code", "STANDARD")?.[0]?.id;

      automotiveId = dataUtils.getObjectByFieldValue<AudienceTypesDto>(qyAudienceType.data, "code", "AUTOMOTIVE")?.[0]?.id;

      firstPartyDataId = dataUtils.getObjectByFieldValue<AudienceTypesDto>(qyAudienceType.data, "code", "FIRST_PARTY")?.[0]?.id;

      cpgId = dataUtils.getObjectByFieldValue<AudienceTypesDto>(qyAudienceType.data, "code", "CPG")?.[0]?.id;
    }

    const apiBase = new objectApi.audiences();
    let data = dataObject.getDataObject("audiences", {
      audienceType: "audienceTypes/" + standardId,
      name: "Untitled Audience",
      account: `account/${accountId}`,
    });

    if (pageId === PageId.audienceBuilderFormTravel) {
      if (!travelId) return;
      data.name = "Untitled Travel Audience";
      data.audienceType = "audienceTypes/" + travelId;
      data.color = "#fca130";
    }

    if (pageId === PageId.audienceBuilderFormInterest) {
      if (!interestId) return;
      data.name = "Untitled Interest Audience";
      data.audienceType = "audienceTypes/" + interestId;
      data.color = "#00B5E2";
    }

    if (pageId === PageId.audienceBuilderFormAutomotive) {
      if (!automotiveId) return;
      data.name = "Untitled Automotive Audience";
      data.audienceType = "audienceTypes/" + automotiveId;
      data.color = "#217CD0";
    }

    if (pageId === PageId.audienceBuilderFirstPartyData) {
      if (!firstPartyDataId) return;
      data.name = "Untitled My Data Audience";
      data.audienceType = "audienceTypes/" + firstPartyDataId;
      data.color = "#fca130";
    }
    if (pageId === PageId.audienceBuilderCPG) {
      if (!firstPartyDataId) return;
      data.name = "Untitled CPG Audience";
      data.audienceType = "audienceTypes/" + cpgId;
      data.color = "#fca130";
    }

    const openFrameId = "audience-builder-update";

    try {
      useUiStore.setState({ activeFrame: pageId });
      apiBase.create(data).then((res: any) => {
        if (res.id) {
          setloadState(undefined);
          if (openFrameId) {
            navigate(pageUtils.getPagePathById(openFrameId, { id: res.id }));
          }
        }
      });
    } catch (e: any) {
      setloadState(undefined);
    }
  };

  return {
    createAudience: createAudience,
    loading: loadState,
  };
};
