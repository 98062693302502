import { ReactComponent as Approval } from "./Approval.svg";
import { ReactComponent as LockIcon } from "./Lock.svg";
import { ReactComponent as UpgradeIcon } from "./Upgrade.svg";
import { ReactComponent as YourfeedbackIcon } from "./Yourfeedback.svg";
import { ReactComponent as AudiencePlanIcon } from "./accountDetails/audiencePlanIcon.svg";
import { ReactComponent as EnterprisePlanIcon } from "./accountDetails/enterprisePlanIcon.svg";
import { ReactComponent as EnterprisePlusPlanIcon } from "./accountDetails/enterprisePlusIcon.svg";
import { ReactComponent as NoSubscriptionPlan } from "./accountDetails/noSubscriptionPlan.svg";
import { ReactComponent as TeamPlanIcon } from "./accountDetails/teamPlanIcon.svg";
import { ReactComponent as AccountEditIcon } from "./accountEdit.svg";
import { ReactComponent as ArrowDown } from "./arrowDown.svg";
import { ReactComponent as ArrowRightIcon } from "./arrowRight.svg";
import { ReactComponent as ArrowUp } from "./arrowUp.svg";
import { ReactComponent as AudienceBuilderIcon } from "./audienceBuilder.svg";
import { ReactComponent as ActivateAccountIcon } from "./auth/activateAccountIcon.svg";
import { ReactComponent as LoginIcon } from "./auth/loginIcon.svg";
import { ReactComponent as ResetPasswordIcon } from "./auth/resetPasswordIcon.svg";
import { ReactComponent as BellIcon } from "./bell.svg";
import { ReactComponent as BellWithDotIcon } from "./bellWithDot.svg";
import { ReactComponent as ChatIcon } from "./chat.svg";
import { ReactComponent as CheckboxChecked } from "./checkboxChecked.svg";
import { ReactComponent as CheckboxIndeterminate } from "./checkboxIndeterminate.svg";
import { ReactComponent as CheckboxUnChecked } from "./checkboxUnchecked.svg";
import { ReactComponent as ChevronDownIcon } from "./chevronDown.svg";
import { ReactComponent as ChevronDownBlue } from "./chevronDownBlue.svg";
import { ReactComponent as Close } from "./close.svg";
import { ReactComponent as CloseLight } from "./closeLight.svg";
import { ReactComponent as CompanyIcon } from "./companyIcon.svg";
import { ReactComponent as CompanyListIconBig } from "./companyListBig.svg";
import { ReactComponent as ContractListIconBig } from "./contractsListBig.svg";
import { ReactComponent as CreateAudienceIcon } from "./createAudience.svg";
import { ReactComponent as CompanyListIcon } from "./dashboard/companyList.svg";
import { ReactComponent as ContractListIcon } from "./dashboard/contractList.svg";
import { ReactComponent as CountIcon } from "./dashboard/count.svg";
import { ReactComponent as DashboardIconBig } from "./dashboard/dashboardBig.svg";
import { ReactComponent as ListIconEmpty } from "./dashboard/listIconEmpty.svg";
import { ReactComponent as PaymentBillingIcon } from "./dashboard/paymentsBilling.svg";
import { ReactComponent as SubscriptionsIcon } from "./dashboard/subscriptions.svg";
import { ReactComponent as UserListIcon } from "./dashboard/userList.svg";
import { ReactComponent as DashboardIcon } from "./dashboardIcon.svg";
import { ReactComponent as DataVaultListIcon } from "./dataVaultList.svg";
import { ReactComponent as DateIcon } from "./dateIcon.svg";
import { ReactComponent as EllipseOff } from "./ellipseOff.svg";
import { ReactComponent as EllipseOn } from "./ellipseOn.svg";
import { ReactComponent as ErrorIcon } from "./errorIcon.svg";
import { ReactComponent as Logo } from "./evorraLogo.svg";
import { ReactComponent as FeedbackIcon } from "./feedback.svg";
import { ReactComponent as HelpIcon } from "./lifeBuoy.svg";
import { ReactComponent as MagnifyGlassIcon } from "./magnifyGlass.svg";
import { ReactComponent as MagnifyGlassBlue } from "./magnifyGlassBlue.svg";
import { ReactComponent as SubscriptionsIconBig } from "./pageIcons/subscriptionsBig.svg";
import { ReactComponent as CheckboxFalse } from "./radioCheckboxFalse.svg";
import { ReactComponent as CheckboxTrue } from "./radioCheckboxTrue.svg";
import { ReactComponent as SearchIcon } from "./search.svg";
import { ReactComponent as SettingsIcon } from "./settings.svg";
import { ReactComponent as SignOutIcon } from "./signOut.svg";
import { ReactComponent as SortIcon } from "./sortIconAsc.svg";
import { ReactComponent as SortNone } from "./sortIconNone.svg";
import { ReactComponent as SpeechBubbleIcon } from "./speechBubble.svg";
import { ReactComponent as UserList } from "./userList.svg";
import { ReactComponent as UserListIconBig } from "./userListBig.svg";

import { ReactComponent as Audience } from "./audience.svg";
import { ReactComponent as CardHolder } from "./cardHolder.svg";
import { ReactComponent as CaretDown } from "./caretDown.svg";
import { ReactComponent as ChannelIcon } from "./channel.svg";
import { ReactComponent as Clock } from "./clock.svg";
import { ReactComponent as Duplicate } from "./duplicate.svg";
import { ReactComponent as Edit } from "./edit.svg";
import { ReactComponent as EyeDropper } from "./eyeDropper.svg";
import { ReactComponent as HandTool } from "./handTool.svg";
import { ReactComponent as Info } from "./info.svg";
import { ReactComponent as InfoIcon } from "./infoIcon.svg";
import { ReactComponent as Minus } from "./minus.svg";
import { ReactComponent as Plus } from "./plus.svg";
import { ReactComponent as Redo } from "./redo.svg";
import { ReactComponent as Sheets } from "./sheets.svg";
import { ReactComponent as SuccessIcon } from "./successIcon.svg";
import { ReactComponent as TeamIcon } from "./team.svg";
import { ReactComponent as Tip } from "./tip.svg";
import { ReactComponent as Trash } from "./trash.svg";
import { ReactComponent as Undo } from "./undo.svg";
import { ReactComponent as Vault } from "./vault.svg";
import { ReactComponent as VueMetre } from "./vueMetre.svg";
import { ReactComponent as WarningIcon } from "./warningIcon.svg";
import { ReactComponent as ZoomActive } from "./zoomActive.svg";
import { ReactComponent as ZoomIn } from "./zoomIn.svg";
import { ReactComponent as ZoomOut } from "./zoomOut.svg";

import { ReactComponent as Basics } from "./basics.svg";
import { ReactComponent as Channels } from "./channels.svg";
import { ReactComponent as Demographics } from "./demographics.svg";
import { ReactComponent as Favorite } from "./favorite.svg";
import { ReactComponent as FileSearch } from "./fileSearch.svg";
import { ReactComponent as Filters } from "./filters.svg";
import { ReactComponent as Grid } from "./grid.svg";
import { ReactComponent as List } from "./list.svg";
import { ReactComponent as Strategy } from "./strategy.svg";
import { ReactComponent as Time } from "./time.svg";
import { ReactComponent as User } from "./user.svg";
import { ReactComponent as UserGroup } from "./userGroup.svg";
import { ReactComponent as VerticalEllipsis } from "./verticalEllipsis.svg";

import { ReactComponent as Audiences } from "./audiences.svg";
import { ReactComponent as DashboardIconMain } from "./dashboardIconBig.svg";
import { ReactComponent as DataSources } from "./datasources.svg";
import { ReactComponent as Location } from "./location.svg";
import { ReactComponent as Metrics } from "./metrics.svg";
import { ReactComponent as DownloadIcon } from "./metrics/downloadIcon.svg";
import { ReactComponent as MetricsData } from "./metrics/metricsData.svg";
import { ReactComponent as MetricsError } from "./metrics/metricsError.svg";
import { ReactComponent as MetricsFeatures } from "./metrics/metricsFeatures.svg";
import { ReactComponent as Move } from "./move.svg";
import { ReactComponent as MyListsIcon } from "./myLists.svg";
import { ReactComponent as Permissions } from "./permissions.svg";
import { ReactComponent as TransparencyCard } from "./transparencyCard.svg";

import { ReactComponent as Upload } from "./Upload.svg";
import { ReactComponent as Age } from "./age.svg";
import { ReactComponent as HourGlass } from "./hourglass.svg";
import { ReactComponent as Oclock } from "./oclock.svg";

import { ReactComponent as ExclInclu } from "./ExclInclu.svg";
import { ReactComponent as ExclIncluFilled } from "./ExclIncluFilled.svg";
import { ReactComponent as JoinOperator } from "./joinOperator.svg";

import { ReactComponent as GlobalCockpitIcon } from "./dashboard/globalcockpit.svg";

import { ReactComponent as AdForm } from "./brands/adform.svg";
import { ReactComponent as Amazon } from "./brands/amazon.svg";
import { ReactComponent as Beeswax } from "./brands/beeswax.svg";
import { ReactComponent as Crimtan } from "./brands/crimtan.svg";
import { ReactComponent as DV360 } from "./brands/dv360.svg";
import { ReactComponent as GAds } from "./brands/gads.svg";
import { ReactComponent as Hawk } from "./brands/hawk.svg";
import { ReactComponent as Jamloop } from "./brands/jamloop.svg";
import { ReactComponent as Liveramp } from "./brands/liveramp.svg";
import { ReactComponent as Lotame } from "./brands/lotame.svg";
import { ReactComponent as Magnite } from "./brands/magnite.svg";
import { ReactComponent as Mediagrid } from "./brands/mediagrid.svg";
import { ReactComponent as Meta } from "./brands/meta.svg";
import { ReactComponent as Pubmatic } from "./brands/pubmatic.svg";
import { ReactComponent as Quantcast } from "./brands/quantcast.svg";
import { ReactComponent as RtbHouse } from "./brands/rtbhouse.svg";
import { ReactComponent as StackAdapt } from "./brands/stackadapt.svg";
import { ReactComponent as TheTradeDesk } from "./brands/thetradedesk.svg";
import { ReactComponent as Xandr } from "./brands/xandr.svg";
import { ReactComponent as Yahoo } from "./brands/yahoo.svg";
import { ReactComponent as HeaderClient } from "./headerClient.svg";

import { ReactComponent as Travel } from "./travel.svg";

import { ReactComponent as DataCollection } from "./dataCollection.svg";
import { ReactComponent as TravelSearches } from "./travelSearches.svg";

import { ReactComponent as DownloadCollection } from "./downloadCollection.svg";
import { ReactComponent as Drop } from "./drop.svg";
import { ReactComponent as MapScales } from "./mapscales.svg";
import { ReactComponent as Pdf } from "./pdf.svg";
import { ReactComponent as Robot } from "./robot.svg";

import { ReactComponent as Archive } from "./archive.svg";
import { ReactComponent as ChannelsNav } from "./channelsNav.svg";
import { ReactComponent as ErrorBackground } from "./errorBackground.svg";
import { ReactComponent as Eye } from "./eye.svg";
import { ReactComponent as Recency } from "./recency.svg";
import { ReactComponent as Route } from "./route.svg";
import { ReactComponent as TravelPeriod } from "./travelPeriod.svg";

import { ReactComponent as LogoEvorra } from "./iconLogo.svg";
import { ReactComponent as StepDone } from "./stepDone.svg";
import { ReactComponent as StepUnDone } from "./stepUnDone.svg";

import { ReactComponent as Boxes } from "./boxes.svg";
import { ReactComponent as logoAdmin } from "./logoAdmin.svg";
import { ReactComponent as MyProfile } from "./myProfile.svg";
import { ReactComponent as ShoppingCart } from "./shopping-cart.svg";
import { ReactComponent as Store } from "./store.svg";
//
import { ReactComponent as NoChanges } from "./noChanges.svg";
import { ReactComponent as SortAZ } from "./sortAZ.svg";
import { ReactComponent as SortAsc } from "./sortAsc.svg";
import { ReactComponent as SortDesc } from "./sortDesc.svg";
import { ReactComponent as SortZA } from "./sortZA.svg";

import { ReactComponent as Brand } from "./brand.svg";
import { ReactComponent as Payments } from "./payments.svg";
import { ReactComponent as UserManagement } from "./usermanagement.svg";

import { ReactComponent as AdvancedBuilder } from "./advancedBuilder.svg";
import { ReactComponent as InterestBuilder } from "./interestBuilder.svg";

import { ReactComponent as Account } from "./account.svg";
import { ReactComponent as Carinterest } from "./car-interest.svg";
import { ReactComponent as CarSteeringWheel, ReactComponent as Carowned } from "./car-steering-wheel.svg";
import { ReactComponent as CarVariant } from "./car-variant.svg";
import { ReactComponent as Car } from "./car.svg";
import { ReactComponent as Amex } from "./creditCards/amex.svg";
import { ReactComponent as Mastercard } from "./creditCards/mastercard.svg";
import { ReactComponent as Visa } from "./creditCards/visa.svg";
import { ReactComponent as Flag } from "./flag.svg";
import { ReactComponent as HandShake } from "./handshake.svg";
import { ReactComponent as HouseholdIncome } from "./householdincome.svg";
import { ReactComponent as Plug } from "./plug.svg";
import { ReactComponent as Politics } from "./politics.svg";
import { ReactComponent as Tools } from "./tools.svg";
import { ReactComponent as Wallet } from "./wallet.svg";

import { ReactComponent as Notification } from "./notification.svg";

import { ReactComponent as Education } from "./education.svg";

import { ReactComponent as File } from "./file.svg";
//@deprecated
export {
  Account,
  ActivateAccountIcon,
  AdForm,
  Approval,
  ArrowRightIcon,
  AudiencePlanIcon,
  Beeswax,
  BellIcon,
  BellWithDotIcon,
  Boxes,
  CarSteeringWheel,
  CarVariant,
  Carinterest,
  Carowned,
  ChannelIcon,
  ChatIcon,
  CheckboxChecked,
  CheckboxFalse,
  CheckboxIndeterminate,
  CheckboxTrue,
  CheckboxUnChecked,
  ChevronDownBlue,
  ChevronDownIcon,
  Close,
  CloseLight,
  CompanyIcon,
  CompanyListIcon,
  CompanyListIconBig,
  ContractListIcon,
  ContractListIconBig,
  CountIcon,
  DashboardIcon,
  DashboardIconBig,
  DataVaultListIcon,
  DateIcon,
  Education,
  EllipseOff,
  EllipseOn,
  EnterprisePlanIcon,
  EnterprisePlusPlanIcon,
  ErrorIcon,
  FeedbackIcon,
  File,
  Flag,
  GlobalCockpitIcon,
  HandShake,
  Hawk,
  HelpIcon,
  ListIconEmpty,
  LockIcon,
  LoginIcon,
  Logo,
  LogoEvorra,
  MagnifyGlassBlue,
  MagnifyGlassIcon,
  NoSubscriptionPlan,
  PaymentBillingIcon,
  Plug,
  Politics,
  Boxes as Products,
  ResetPasswordIcon,
  SearchIcon,
  SettingsIcon,
  ShoppingCart,
  SignOutIcon,
  SortIcon,
  SortNone,
  SpeechBubbleIcon,
  Store,
  Store as Stores,
  SubscriptionsIcon,
  SubscriptionsIconBig,
  TeamIcon,
  TeamPlanIcon,
  Tools,
  UpgradeIcon,
  UserListIcon,
  UserListIconBig,
  Wallet,
  YourfeedbackIcon,
  logoAdmin,
};

const IconSet = {
  Logo,
  LogoEvorra,
  logoAdmin,
  DashboardIcon,
  CompanyIcon,
  ChatIcon,
  FeedbackIcon,
  BellIcon,
  BellWithDotIcon,
  HelpIcon,
  SettingsIcon,
  MagnifyGlassIcon,
  ChevronDownIcon,
  CompanyListIcon,
  ContractListIcon,
  DataVaultListIcon,
  PaymentBillingIcon,
  ListIconEmpty,
  CountIcon,
  SubscriptionsIcon,
  UserListIcon,
  DashboardIconBig,
  CompanyListIconBig,
  ContractListIconBig,
  UserListIconBig,
  SubscriptionsIconBig,
  CheckboxFalse,
  CheckboxTrue,
  ActivateAccountIcon,
  AccountEditIcon,
  LoginIcon,
  EllipseOn,
  EllipseOff,
  CheckboxChecked,
  CheckboxUnChecked,
  CheckboxIndeterminate,
  ResetPasswordIcon,
  MagnifyGlassBlue,
  Close,
  CloseLight,
  ChevronDownBlue,
  SortIcon,
  SortNone,
  DateIcon,
  AudiencePlanIcon,
  EnterprisePlanIcon,
  EnterprisePlusPlanIcon,
  NoSubscriptionPlan,
  TeamPlanIcon,
  ErrorIcon,
  UpgradeIcon,
  SpeechBubbleIcon,
  SignOutIcon,
  ArrowRightIcon,
  SearchIcon,
  CreateAudienceIcon,
  AudienceBuilderIcon,
  Clock,
  Duplicate,
  Edit,
  Trash,
  Undo,
  Redo,
  CaretDown,
  Info,
  VueMetre,
  EyeDropper,
  ZoomActive,
  ZoomIn,
  ZoomOut,
  HandTool,
  Sheets,
  Tip,
  Audience,
  Plus,
  Minus,
  Vault,
  WarningIcon,
  SuccessIcon,
  InfoIcon,
  CardHolder,
  Favorite,
  FileSearch,
  Grid,
  List,
  Time,
  User,
  UserGroup,
  VerticalEllipsis,
  Demographics,
  Basics,
  Strategy,
  Filters,
  Channels,
  Audiences,
  MyListsIcon,
  DashboardIconMain,
  Permissions,
  Metrics,
  MetricsData,
  MetricsFeatures,
  MetricsError,
  DownloadIcon,
  UserList,
  Move,
  TransparencyCard,
  Location,
  DataSources,
  HourGlass,
  Age,
  Oclock,
  Upload,
  ExclInclu,
  ExclIncluFilled,
  JoinOperator,
  GlobalCockpitIcon,
  GAds,
  Amazon,
  TheTradeDesk,
  DV360,
  Meta,
  Lotame,
  Crimtan,
  RtbHouse,
  StackAdapt,
  Xandr,
  Quantcast,
  Yahoo,
  Jamloop,
  Magnite,
  Pubmatic,
  Mediagrid,
  Liveramp,
  HeaderClient,
  Travel,
  TravelSearches,
  SortAsc,
  SortDesc,
  SortAZ,
  SortZA,
  NoChanges,
  ArrowUp,
  ArrowDown,
  DataCollection,
  DownloadCollection,
  Pdf,
  Robot,
  MapScales,
  Drop,
  Archive,
  ErrorBackground,
  TravelPeriod,
  Recency,
  Route,
  ChannelsNav,
  Eye,
  StepDone,
  StepUnDone,
  MyProfile,
  Brand,
  Payments,
  UserManagement,
  InterestBuilder,
  AdvancedBuilder,
  YourfeedbackIcon,
  LockIcon,
  TeamIcon,
  ChannelIcon,
  Approval,
  HouseholdIncome,
  Car,
  HandShake,
  CarVariant,
  Plug,
  Tools,
  Wallet,
  CarSteeringWheel,
  Carowned,
  Carinterest,
  Account,
  Flag,
  Notification,
  Mastercard,
  Visa,
  Amex,
  Politics,
  Beeswax,
  Hawk,
  Education,
  AdForm,
  File,
  ShoppingCart,
  Boxes,
  Store,
  Stores: Store,
  Products: Boxes,
} as const;

export { IconSet };
export type TIcon = keyof typeof IconSet;

/*
Examples :
<IconSet.WarningIcon />
<AppIcon size={'large'} icon={'WarningIcon'} />
*/
