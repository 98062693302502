import {Box, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {styled} from "@mui/styles";
import {IFiltersValues} from "pages/AudienceBuilder/types/audienceBuilderTypes";
import {FeatureGrid, ViewAudienceFeatureInterest} from "./Cards/ViewAudienceFeatureInterest";
import {ViewAudienceLinkedId} from "./Cards/ViewAudienceLinkedId";
import {ViewAudienceSlider} from "./Cards/ViewAudienceSlider";
import {ViewAudienceSubFeatureCheckList} from "./Cards/ViewAudienceSubFeatureCheckList";
import {ViewAudienceSubFeatureDate} from "./Cards/ViewAudienceSubFeatureDate";
import {ViewDate} from "./Cards/viewDate";
import {AgeOfChildrenFeature} from "./Cards/AgeOfChildrenFeature";

const PercentageBar = (props: { value: number }) => {
    return (
        <>
            <div style={{display: "flex", width: "100%", gap: "7px", alignItems: "center"}}>
                <div style={{
                    backgroundColor: "#eee",
                    width: "100%",
                    position: "relative",
                    height: "10px",
                    borderRadius: "5px"
                }}>
                    <div style={{
                        background: "#9ba8bd",
                        width: props.value + "%",
                        height: "10px",
                        position: "absolute",
                        borderRadius: "5px"
                    }}></div>
                </div>
                <Typography sx={{fontSize: "10px", color: "#7587A3", fontWeight: "bold", lineHeight: "10px"}}
                            data-cy="percentage-card-value">
                    {props.value}%
                </Typography>
            </div>
        </>
    );
};

export function drawAudienceFeatures(filterValue: IFiltersValues, viewMode: boolean = false) {
    let ret = null;

    if (filterValue.code === 'AGE_CHILDREN') return <Box><AgeOfChildrenFeature filterValue={filterValue}/></Box>

    switch (filterValue.type) {
        case "linkedId":
            if (filterValue.code !== "INTEREST") {
                ret = (
                    <Box>
                        <ViewAudienceLinkedId filterValue={filterValue} viewMode={viewMode}/>
                    </Box>
                );
            } else {
                ret = (
                    <Box>
                        <ViewAudienceFeatureInterest filterValue={filterValue} viewMode={viewMode}/>
                    </Box>
                );
            }
            break;

        case "slider":
            ret = <ViewAudienceSlider filterValue={filterValue}/>;
            break;
        case "percentage":
            ret = (
                <Box sx={{p: 0.5}}>
                    <PercentageBar value={Number(filterValue.selected[0] ?? 0)}></PercentageBar>
                </Box>
            );
            break;
        case "arrayString":
            ret = (
                <FeatureGrid data-cy={"arrayStringFeature"}>
                    {filterValue.selected.map((text: string, idx) => {
                        const label = filterValue.selectable.find((item) => item.value === text)?.label;
                        if (label === undefined) {
                            return (
                                <FeatureLabel data-cy={"arrayLabel"} data-cy-value={text} key={idx}>
                                    {text}
                                </FeatureLabel>
                            );
                        }
                        return (
                            <FeatureLabel data-cy={"arrayLabel"} data-cy-value={label} key={idx}>
                                {label}
                            </FeatureLabel>
                        );
                    })}
                </FeatureGrid>
            );
            break;
        case "templateUrlList":
            ret = filterValue.selected.map((fileCode: any) => {
                const fileName = filterValue.selectable.find((selectable) => selectable.code === fileCode)?.name || "unknow";
                return (
                    <FeatureLabel style={{maxWidth: "145px", marginRight: "0.5rem"}} title={fileName}>
                        {fileName}
                    </FeatureLabel>
                );
            });

            break;
        case "date":
            if (filterValue.parentCode) {
                ret = <ViewAudienceSubFeatureDate values={filterValue.selected}/>
            } else {
                ret = <ViewDate values={filterValue.selected[0]}/>
            }


            break;
        case "checklist":
            ret = <ViewAudienceSubFeatureCheckList values={filterValue.selected[0]}/>;
            break;
    }

    return ret ?? null;
}

export const FeatureLabel = styled("div")((props: { theme: Theme; mode?: "single" | "grouped" }) => ({
    fontSize: "8px!important",
    display: "inline-block",
    textOverflow: "ellipsis",
    position: "relative",
    lineHeight: "12px",
    overflow: "hidden",
    borderRadius: "64px",
    border: "1px solid " + props.theme.skin.borderColor,
    padding: "0 0.5rem",
    maxWidth: "130px",
    whiteSpace: "nowrap",
    backgroundColor: props.mode === "grouped" ? props.theme.palette.background.default : props.theme.palette.background.paper,
    "&:hover": {
        border: "1px solid rgba(0,0,0,0.6)",
        boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
    },
}));
