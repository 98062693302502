import {Box, Button, CircularProgress, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {stripeService} from "application/services/stripe.service";
import {moneyFormatter} from "application/utils/formatters.utils";
import {StyledCheckbox} from "components/form/CheckBox";
import {useAppToast} from "components/live/AppToast";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {BackendError} from "types/backendError";
import {StepDescription, StepTitle} from "..";
import {useAudienceEnablementStore} from "../store/AudienceEnablement.store";
import {convertSummaryToFinalDspSelection} from "../utils";
import {SummaryTable} from "./SummaryStep/SummaryTable";
import {shallow} from "zustand/shallow";

export const SummaryStep = () => {
    const {t} = useTranslation();
    const toast = useAppToast();

    const audienceData = useAudienceEnablementStore((state) => state.audienceData);

    const actions = useAudienceEnablementStore((state) => state.actions);
    const selectionStepForm = useAudienceEnablementStore((state) => state.selectionStepForm);
    const currency = useAudienceEnablementStore((state) => state.audienceData.displayCurrency);
    const totalSpentPayOnce = useAudienceEnablementStore((st) => st.totalSpentPayOnce);
    const paymentInProgress = useAudienceEnablementStore((st) => st.paymentInProgress);
    const paymentType = useAudienceEnablementStore((st) => st.paymentType);
    const selectedCardMethod = useAudienceEnablementStore((st) => st.selectedCardMethod);
    const isCpmSetToZero = useAudienceEnablementStore((state) => state.isCpmSetToZero);
    const campaignEndDates = useAudienceEnablementStore(
        (state) => state.selectionStepForm.reduce((acc, item) => ({...acc, [item.code]: item.campaignEndDate}), {}),
        shallow
    ); //used for rerender when campaignEndDates change
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

    const audiencesApi = new objectApi.audiences();

    const summary = selectionStepForm.filter((item) => item.profiles > 0);

    let finalDspSelection = convertSummaryToFinalDspSelection(summary);

    const payAndActivate = () => {
        if (finalDspSelection) {
            if (audienceData.audienceStatus === "APPROVED") {
                audiencesApi
                    .enableApprovedAudience(audienceData.id, {
                        payLater: paymentType === "invoice",
                        paymentMethodId: selectedCardMethod.id,
                    })
                    .then((res) => {
                        actions.goToNextStep();
                    })
                    .catch((err: BackendError) => {
                        toast.error({
                            message: err?.error?.message ?? "An error occurred",
                        });
                        useAudienceEnablementStore.setState({paymentInProgress: false});
                    });
            } else {
                stripeService
                    .stripeCreateAudienceInvoice(audienceData.id, {
                        dspSelection: finalDspSelection,
                        payLater: paymentType === "invoice",
                        paymentMethodId: selectedCardMethod.id,
                    })
                    .then((res) => {
                        actions.goToNextStep();
                    })
                    .catch((err: BackendError) => {
                        toast.error({
                            message: err?.error?.message ?? "An error occurred",
                        });
                        useAudienceEnablementStore.setState({paymentInProgress: false});
                    });
            }
        }
    };

    return (
        <>
            <StepTitle>
                <span>Review & Send</span>
                {!paymentInProgress && (
                    <Button variant="outlined" onClick={() => actions.goToPreviousStep()}>
                        Back
                    </Button>
                )}
            </StepTitle>
            <StepDescription>Verify all your audience details before enablement.</StepDescription>

            <Box p={2} mb={4} sx={{background: "#fafafa", borderRadius: "8px", border: "1px solid #ececec"}}>
                <SummaryTable/>
            </Box>

            {totalSpentPayOnce > 0 && (
                <Box p={2} mb={4} sx={{background: "#fafafa", borderRadius: "8px", border: "1px solid #ececec"}}>
                    <Box sx={{display: "grid", gap: "1rem", gridTemplateColumns: "1fr  110px", alignItems: "center"}}>
                        <Typography variant="body1" sx={{fontSize: "16px", fontWeight: "bold"}}>
                            Total charges
                            <span className="color-tech-grey"
                                  style={{paddingLeft: "10px", fontSize: "12px", fontWeight: "normal"}}>
                - Payment {paymentType === "card" ? `by credit card XXXX XXXX XXXX ${selectedCardMethod.card.last4}` : "by invoice"}
              </span>
                        </Typography>
                        <Box>
                            <Typography variant="body1"
                                        sx={{textAlign: "center", fontSize: "16px", fontWeight: "bold"}}>
                                {moneyFormatter(totalSpentPayOnce ?? 0, {currency: currency})}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            )}

            {totalSpentPayOnce === 0 && !isCpmSetToZero && (
                <Box p={2} mb={4} sx={{background: "#fafafa", borderRadius: "8px", border: "1px solid #ececec"}}>
                    <Typography variant="body1" sx={{fontSize: "14px", fontWeight: "bold", textAlign: "center"}}>
                        You selected "Impression Based Buy", there is no payment due now. You will be charged based on
                        the number of impressions delivered.
                    </Typography>
                </Box>
            )}
            <Box mt={4} sx={{display: "flex", alignItems: "center", gap: "1rem"}}>
                <StyledCheckbox
                    name={"termsAndConditionsAccepted"}
                    data-cy={"accept-conditions"}
                    onChange={(event) => setTermsAccepted(event?.target?.checked)}
                    checked={termsAccepted}
                />
                <Typography variant="body1" sx={{cursor: "pointer"}} onClick={() => setTermsAccepted(!termsAccepted)}>
                    {totalSpentPayOnce === 0 ? t("audience.activation.confirmTermsConditionsIBB") : t("audience.activation.confirmTermsConditions")}
                </Typography>
            </Box>

            <Box mt={4} sx={{textAlign: "center"}}>
                {paymentInProgress ? (
                    <Button disabled={true} variant={"contained"}>
                        <CircularProgress size="1rem"/> <span style={{paddingLeft: "10px"}}>Activation in progress, please wait...</span>
                    </Button>
                ) : (
                    <Button
                        disabled={!termsAccepted}
                        variant={"contained"}
                        type={"submit"}
                        data-cy={"pay-btn"}
                        onClick={() => {
                            useAudienceEnablementStore.setState({paymentInProgress: true});
                            payAndActivate();
                        }}
                    >
                        {totalSpentPayOnce === 0 ? t("audience.activation.payAndEnableIBB") : t("audience.activation.payAndEnable")}
                    </Button>
                )}
            </Box>
        </>
    );
};
