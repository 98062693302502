import {AxiosResponse} from "axios";
import {BackendError} from "../../../types/backendError";
import {apiBuilder, apiService, IApiReturn, IPageParameters, ISortParameters} from "../api/apiService";
import {TEntities} from "../dataTypes";
import {IAccountConfParameter, IAccounts} from "../dataTypes/accounts";
import {IBrandRoles} from "../dataTypes/brandRoles";
import {IDashboardDataDto} from "../dataTypes/dashboardData";
import {IIndustry} from "../dataTypes/industries";
import {ITags} from "../dataTypes/tags";
import {AbstractBaseApi} from "./AbstractBaseApi";

export class Accounts extends AbstractBaseApi<IAccounts> {
    public routes: Record<string, string> = {};

    private apiIndustries: apiBuilder;
    private apiAudiences;
    private apiDashBoard;
    private apiNaturalFind;
    private apiExclusionListFiles;
    private apiDeleteFiles;
    private apiDomainListFiles;

    /** migrate to agencies */

    constructor() {
        super("accounts");

        this.apiIndustries = apiService.entity(["accounts", "industries"]);
        this.apiAudiences = apiService.entity(["accounts", "audiences"], {showProjection: false});
        this.apiDashBoard = apiService.entity(["accounts", "dashboardData"]);
        this.apiNaturalFind = apiService.entity(["accounts", "audiences"]);
        this.apiExclusionListFiles = apiService.entity(["accounts", "listExclusionListFiles"]);
        this.apiDomainListFiles = apiService.entity(["accounts", "listDomainListFiles"]);
        this.apiDeleteFiles = apiService.entity(["accounts", "deleteFile"]);

        this.routes["GetMyFeatureFlags"] = "accounts/{accountId}/featureFlags";
        this.routes["GetDspChannels"] = "accounts/{accountId}/dspChannels";
        this.routes["Tags"] = "accounts/{accountId}/tags";
        this.routes["Rules"] = "accounts/{accountId}/rules";
        this.routes["Audiences"] = "accounts/{accountId}/audiences";
        this.routes["AudiencesStatusCount"] = "accounts/{accountId}/audiences/search/getAudienceStatusCount";
        this.routes["createBrandAccount"] = "accounts/{accountId}/createBrandAccount";
        this.routes["inviteUser"] = "accounts/{accountId}/inviteUser";
        this.routes["inviteBrandUser"] = "accounts/{accountId}/inviteUserInBrand";
        this.routes["brandAccountAssociations"] = "accounts/{accountId}/brandAccountAssociations";
        this.routes["getCompanyUsers"] = "accounts/{accountId}/companyUsers";
        this.routes["archiveBrand"] = "accounts/{accountId}/archiveBrand/{brandId}";
        this.routes["brandRoles"] = "accounts/{accountId}/brandRoles/{brandId}";
        this.routes["searchFull"] = "accounts/{accountId}/audiences/searchFull";
        this.routes["getAccountConfParameters"] = "accounts/{accountId}/accountConfParameters";
    }

    private static prepare(data: Partial<IAccounts>): Partial<IAccounts> {
        const dta = {...data};

        delete dta.affinities;
        //@ts-ignore
        delete dta._links;

        return dta;
    }

    update(id: number, data: Partial<IAccounts>) {
        return super.update(id, Accounts.prepare(data));
    }

    naturalFind(args: {
        searchValue: string | number;
        status?: "Activated" | "Draft" | "Archived" | string;
        byId?: number;
        page?: IPageParameters;
        sort?: ISortParameters;
        ret?: IApiReturn;
    }) {
        return this.apiNaturalFind
            .byId(args.byId)
            .naturalFind(args.searchValue)
            .addParameter("status", args?.status)
            .page(args?.page)
            .sort(args?.sort)
            .fetch(args?.ret)
            .then((res) => res)
            .catch((error: any) => {
                throw error;
            });
    }

    searchAudiences(args: {
        searchField: string;
        searchValue: string | undefined;
        kwValue?: string | undefined;
        byId?: number;
        page?: IPageParameters;
        sort?: ISortParameters;
        ret?: IApiReturn;
    }) {
        return this.apiNaturalFind
            .byId(args.byId)
            .search()
            .addParameter(args.searchField, args.searchValue)
            .addParameter("kw", args.kwValue)
            .page(args?.page)
            .sort(args?.sort)
            .fetch(args?.ret)
            .then((res) => res)
            .catch((error: any) => {
                throw error;
            });
    }

    getAudiences(
        id: number,
        args?: {
            page?: IPageParameters;
            sort?: ISortParameters;
        }
    ) {
        return apiService
            .entity(["accounts", "audiences"])
            .byId(id)
            .page(args?.page)
            .sort(args?.sort)
            .get()
            .then((res: AxiosResponse<TEntities["audiences"]>) => {
                return res;
            })
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    getUploadedExclusionLists(
        id: number,
        args?: {
            page?: IPageParameters;
            sort?: ISortParameters;
        }
    ) {
        return this.apiExclusionListFiles
            .byId(id)
            .page(args?.page)
            .sort(args?.sort)
            .get()
            .then((res: AxiosResponse<TEntities["listFiles"]>) => {
                return res;
            })
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    getUploadedDomainLists(
        id: number,
        args?: {
            page?: IPageParameters;
            sort?: ISortParameters;
        }
    ) {
        return this.apiDomainListFiles
            .byId(id)
            .page(args?.page)
            .sort(args?.sort)
            .get()
            .then((res: AxiosResponse<TEntities["listFiles"]>) => {
                return res;
            })
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    deleteUploadedList(id: number, key: string) {
        return this.apiDeleteFiles
            .byId(id)
            .delete({params: {fileName: key}})
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    postUploadExclusionFile(id: number, fileData: any, fileName: any, description: any) {
        return apiService
            .entity(["accounts", "uploadExclusionListFile"])
            .byId(id)
            .post(fileData, {
                params: {fileName: fileName, description: description},
                headers: {"Content-type": "multipart/form-data"},
            })
            .then((res) => res);
    }

    postUploadDomainFile(id: number, fileData: any, fileName: any, description: any) {
        return apiService
            .entity(["accounts", "uploadDomainListFile"])
            .byId(id)
            .post(fileData, {
                params: {fileName: fileName, description: description},
                headers: {"Content-type": "multipart/form-data"},
            })
            .then((res) => res);
    }

    getSubscription(id: number) {
        return apiService
            .entity(["accounts", "subscription"])
            .byId(id)
            .get()
            .then((res: AxiosResponse<TEntities["subscriptions"]>) => {
                return res?.data;
            })
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    getVault(id: number) {
        return apiService
            .entity(["accounts", "vault"])
            .byId(id)
            .get()
            .then((res: AxiosResponse<TEntities["vault"]>) => {
                return res?.data;
            });
    }

    createVault(id: number) {
        return apiService
            .entity(["accounts", "createVault"])
            .byId(id)
            .post()
            .then((res) => res);
    }

    getDataPipelineWorkflowJob(id: number) {
        return apiService
            .entity(["accounts", "getDataPipelineWorkflowJob"])
            .byId(id)
            .post()
            .then((res) => res);
    }

    startDataPipelineWorkflow(id: number) {
        return apiService
            .entity(["accounts", "startDataPipelineWorkflow"])
            .byId(id)
            .post()
            .then((res) => res);
    }

    setVaultPassword(id: number, data: any) {
        return apiService
            .entity(["accounts", "setVaultPassword"])
            .byId(id)
            .post(data, {headers: {"Content-type": "application/json"}})
            .then((res) => res);
    }

    getVaultKeys(id: number) {
        return apiService
            .entity(["accounts", "getVaultKeys"])
            .byId(id)
            .post()
            .then((res) => res);
    }

    patchVault(id: number, data: any) {
        return apiService
            .entity(["vaults"])
            .byId(id)
            .patch(data, {headers: {"Content-type": "application/json"}})
            .then((res) => res);
    }

    updateIndustries(id: number, data: Partial<IIndustry>[]) {
        return this.apiIndustries
            .byId(id)
            .put(data, {asUriFragment: true})
            .then((res) => res)
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    async getDashBoard(id: number) {
        return this.apiDashBoard
            .byId(id)
            .get<IDashboardDataDto>()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    buildReport(id: number) {
        return apiService
            .entity(["accounts", "buildReport "])
            .byId(id)
            .post({reportName: "ProviderAudienceRevenue"})
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    providerDataReport(id: number) {
        return apiService
            .entity(["accounts", "providerDataReport"])
            .byId(id)
            .post({})
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    providerDataReportDate(id: number) {
        return apiService
            .entity(["accounts", "providerDataReportDate"])
            .byId(id)
            .get({})
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    createAccount(data: IAccounts) {
        return apiService
            .route(`createAccountWithUserAndSubscription`)
            .post(data)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    activateAccount(id: number) {
        // activateAccount
        return apiService
            .route(`accounts/${id}/activateAccount`)
            .post({})
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    suspendAccount(id: number) {
        // suspendAccount
        return apiService
            .route(`accounts/${id}/suspendAccount`)
            .post({})
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getDspChannelsSelections(accountId: number) {
        return apiService
            .route(`accounts/${accountId}/dspChannelsSelections`)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getDspChannelsSelectionsId(accountId: number, dspChannelsSelectionsId: number) {
        return apiService
            .route(`accounts/${accountId}/dspChannelsSelections/${dspChannelsSelectionsId}`)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    checkDspChannelsSelection(
        accountId: number,
        args: {
            dspChannelCode: string;
            parameterValues: Record<string, any>;
        }
    ) {
        return apiService
            .route(`accounts/${accountId}/checkDspChannelsSelection`)
            .post(args)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    createUpdateDspChannelsSelections(
        accountId: number,
        args: {
            dspChannelCode: string;
            dspEnablementOptions: string[];
            parameterValues: Record<string, any>;
            seatName: string;
        }
    ) {
        return apiService
            .route(`accounts/${accountId}/dspChannelsSelections`)
            .post(args)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getMyFeatureFlags(accountId: number) {
        return apiService
            .getRoute(this.routes["GetMyFeatureFlags"], {accountId})
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    GetDspChannels(accountId: number) {
        return apiService
            .getRoute(this.routes["GetDspChannels"], {accountId})
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    GetTags(accountId: number, args?: { page?: IPageParameters; sort?: ISortParameters; output?: IApiReturn }) {
        return apiService
            .getRoute(this.routes["Tags"], {accountId}, {projection: "tags"})
            .page({pageNumber: 0, pageSize: 150, ...args?.page})
            .sort(args?.sort)
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    CreateTags(accountId: number, args: Partial<ITags>) {
        return apiService
            .getRoute(this.routes["Tags"], {accountId})
            .post({account: "accounts/" + accountId, ...args})
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    GetRules(
        accountId: number,
        args: {
            page?: IPageParameters;
            sort?: ISortParameters;
            ret?: IApiReturn;
        }
    ) {
        return apiService
            .getRoute(this.routes["Rules"], {accountId}, {projection: "rules"})
            .page(args?.page)
            .sort(args?.sort)
            .fetch(args?.ret)
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    GetAudienceStatusCount(accountId: number) {
        return apiService
            .getRoute(this.routes["AudiencesStatusCount"], {accountId})
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    createBrandAccountAssociations(accountId: number, data: IAccounts) {
        return apiService
            .getRoute(this.routes["createBrandAccount"], {accountId})
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    inviteUser(
        accountId: number,
        data: {
            companyRole: string;
            email: string;
            userBrandPermissions?: { accountId: number; role: string }[];
        }
    ) {
        return apiService
            .getRoute(this.routes["inviteUser"], {accountId})
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    inviteBrandUser(
        brandId: number,
        data: {
            email: string;
            userBrandPermission: {
                accountId: number,
                role: string,
                approvalPriceLimit: number,
                approvalRequired: boolean
            };
        }
    ) {
        return apiService
            .getRoute(this.routes["inviteBrandUser"], {accountId: brandId})
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getBrandAccountAssociations(accountId: number) {
        return apiService
            .getRoute(this.routes["brandAccountAssociations"], {accountId}, {projection: "accounts"})
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }


    archiveBrand(accountId: number, brandId: number) {
        return apiService
            .getRoute(this.routes["archiveBrand"], {accountId, brandId}, {projection: "accounts"})
            .post({})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getCompanyUsers(
        accountId: number,
        args?: {
            params?: Record<string, any>;
            page?: IPageParameters;
            sort?: ISortParameters;
        }
    ) {
        return apiService
            .getRoute(this.routes["getCompanyUsers"], {accountId}, {projection: "accounts"})
            .addParameters(args?.params ?? {})
            .page(args?.page)
            .sort(args?.sort)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getBrandRoles(
        accountId: number,
        brandId: number,
        args?: { params?: Record<string, any>; page?: IPageParameters; sort?: ISortParameters }
    ): Promise<AxiosResponse<IBrandRoles>> {
        return apiService
            .getRoute(this.routes["brandRoles"], {accountId, brandId}, {projection: "accounts"})
            .addParameters(args?.params ?? {})
            .page(args?.page)
            .sort(args?.sort)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    searchFullAudiences(args: {
        searchField: string;
        searchValue: string | undefined;
        kwValue?: string | undefined;
        byId?: number;
        page?: IPageParameters;
        sort?: ISortParameters;
        ret?: IApiReturn;
    }) {
        return this.apiNaturalFind
            .byId(args.byId)
            .searchFull()
            .addParameter(args.searchField, args.searchValue)
            .addParameter("kw", args.kwValue)
            .page(args?.page)
            .sort(args?.sort)
            .fetch(args?.ret)
            .then((res) => res)
            .catch((error: any) => {
                throw error;
            });
    }

    getAccountConfParameters(accountId: number) {
        return apiService
            .getRoute(this.routes["getAccountConfParameters"], {accountId})
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    search(arg: { query?: any; page?: IPageParameters; sort?: ISortParameters; ret?: IApiReturn }) {
        return apiService
            .route(`accounts/search`)
            .addParameter("accountType", arg?.query?.accountType !== "ALL" ? arg?.query?.accountType : "")
            .addParameter("dataClient", arg?.query?.dataClient)
            .addParameter("dataProvider", arg?.query?.dataProvider)
            .addParameter("kw", arg?.query?.kw)
            .page(arg?.page)
            .sort(arg?.sort)
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    getGlobalUsers(args?: { params?: Record<string, any>; page?: IPageParameters; sort?: ISortParameters }) {
        return apiService
            .getRoute("companyUsers", {projection: "accounts"})
            .addParameters(args?.params ?? {})
            .page(args?.page)
            .sort(args?.sort)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getAdminAccountConfParameters(accountId: number) {
        return apiService
            .route(`accounts/${accountId}/adminAccountConfParameters`)
            .get()
            .then((res) => res)
            .catch((error) => {
                throw new BackendError(error);
            });
    }

    setAdminAccountConfParameters(
        accountId: number,
        data: {
            paramName: IAccountConfParameter;
            paramValue: boolean | number | string;
        }
    ) {
        return apiService
            .route(`accounts/${accountId}/adminCreateUpdateAccountConfParameters`)
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    postCustomerCreateUpdateAccountConfParameters(
        accountId: number,
        data: {
            paramName: IAccountConfParameter;
            paramValue: number;
        }
    ) {
        return apiService
            .route(`accounts/${accountId}/customerCreateUpdateAccountConfParameters`)
            .post(data)
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    getFirstPartyData(accountId: number) {
        return apiService
            .route(`accounts/${accountId}/getFirstPartyData`)
            .get()
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    generateFirstPartyDataFileCode({accountId, fileName, description}: {
        accountId: number;
        fileName: string;
        description?: string;
    }) {
        return apiService
            .route(`/accounts/${accountId}/generateFirstPartyFileCode`)
            .post({fileName: fileName, description: description ?? ""})
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            })
    }

    uploadFirstPartyData({accountId, fileName, description, data}: {
        accountId: number;
        fileName: string;
        description?: string;
        data: FormData
    }) {
        return apiService
            .route(`accounts/${accountId}/uploadFirstPartyData`)
            .post(data, {
                params: {fileName: fileName, description: description ?? ""},
                headers: {"Content-type": "multipart/form-data"},
            })
            .then((res) => res)
            .catch((error: any) => {
                throw new BackendError(error);
            });
    }

    deleteFirstPartyData(accountId: number, fpdCode: string) {
        return apiService
            .route(`accounts/${accountId}/deleteFirstPartyData/${fpdCode}`)
            .delete()
            .catch((error: any) => {
                throw new Error(error);
            });
    }

    renameFirstPartyData(accountId: number, data: { filecode: string; name: string; description: string }) {
        return apiService
            .route(`accounts/${accountId}/renameFirstPartyData`)
            .post(data)
            .catch((error: any) => {
                throw new Error(error);
            });
    }
}
