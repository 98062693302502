import {Box, Button, ClickAwayListener, Divider, ListItemText, MenuItem, styled, Typography} from "@mui/material";
import Popper from "@mui/material/Popper";
import {FirstPartyDataFile} from "application/entities/dataTypes/firstPartyData";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {AUTHUser} from "application/utils/AuthUser";
import {EPermission} from "application/utils/granter.utils";
import React from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router";
import {useFeatureFlag} from "stores/featureFlags.store";
import {StyledCheckbox} from "../CheckBox";
import {useAudienceBuilderStore} from "../../../pages/AudienceBuilder/Store/AudienceBuilderStore";

export interface PopupSelectListProps<T = Record<string, any>> {
    children?: any;
    items?: FirstPartyDataFile[];
    inputRef?: any;
    field: string;
    text?: string;
    onChange: (expList: string[]) => void;
    selectedItems?: string[];
    accountId?: number;
    disabled?: boolean;
}

export interface CompoundedComponent extends React.ForwardRefExoticComponent<PopupSelectListProps & React.RefAttributes<HTMLInputElement>> {
    Options: () => void;
}

export const PopupSelectList = React.forwardRef((props: PopupSelectListProps, ref: React.Ref<{ actions: any }>) => {
    const {items, text, field, onChange, selectedItems, children, accountId} = props;
    const {t} = useTranslation();
    const featureFlags = useFeatureFlag((st) => st.featureFlags);

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const [list, setList] = React.useState<FirstPartyDataFile[]>(items ?? []);

    const inputRef = React.useRef<null | any>(null);

    const [tempList, setTempList] = React.useState<string[]>(selectedItems ?? []);

    const inclusionLists = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "INCLUSION_LIST";
    })[0];

    const inclusionListsSelected = inclusionLists?.selected?.map((list) => list.code) ?? [];


    React.useImperativeHandle(ref, () => ({actions}));
    const navigate = useNavigate();

    const open = Boolean(anchorEl);

    const actions = {
        close: () => {
            setAnchorEl(null);
        },
        onChange: () => {
        },
    };

    React.useEffect(() => {
        setList(items || []);
        if (selectedItems) setTempList(selectedItems);
    }, [items, selectedItems]);

    const handleInputClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleChange = (code: string) => {
        setTempList((current) => {
            let newValues = [...current];
            if (current.includes(code)) {
                newValues = current.filter((currentCode) => currentCode !== code);
            } else {
                newValues.push(code);
            }

            onChange(newValues);
            return newValues;
        });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const isBrandAdmin =
        AUTHUser.hasRole(EPermission.COMPANYADMIN || AUTHUser.hasRole(EPermission.EVADMIN)) ||
        AUTHUser.permissions?.brandGrades[0]?.brandPermission === EPermission.BRANDADMIN
            ? true
            : false;

    const handleOpenExclusionLists = () => {
        navigate(pageUtils.getPagePathById(PageId.myDatas, {accountId: accountId}));
    };

    return (
        <>
            <Button
                disabled={props.disabled}
                data-cy={"exclusion-list-select-btn"}
                ref={inputRef}
                onClick={handleInputClick}
                sx={{width: "148px"}}
                variant={"outlined"}
            >
                {text}
            </Button>
            <PopperContainer data-cy={"popup-exclusion-list"} anchorEl={anchorEl} placement={"bottom-start"}
                             open={open}>
                <ClickAwayListener onClickAway={handleClose}>
                    <PopupListContainer sx={{width: "148px", padding: "15px"}}>
                        <Typography sx={{color: "#7587A3", marginBottom: "4px", fontSize: "14px"}} variant={"h6"}>
                            Exclusion List
                        </Typography>
                        <Divider/>
                        {!Boolean(list?.length) && (
                            <div>
                                <Typography style={{color: "#7587A3", marginBottom: "10px"}}>There is no exclusion list
                                    available</Typography>
                                {!isBrandAdmin && (
                                    <Typography style={{color: "#7587A3"}}>Please contact your admin if you want to
                                        upload an exclusion list</Typography>
                                )}
                            </div>
                        )}
                        {React.Children.toArray(children).map((child: any, index: any) => {
                            return React.cloneElement(child, {...props});
                        })}

                        {list.map((file, idx: number) => {
                            return (
                                <>
                                    <MenuItem
                                        key={file.code}
                                        data-cy={"exclusion-list-item"}
                                        style={{margin: "0", padding: "7px 0"}}
                                        onClick={() => {
                                            if (inclusionListsSelected.includes(file.code))
                                                return;
                                            handleChange(file.code);
                                        }}
                                    >
                                        <StyledCheckbox checked={tempList.includes(file.code)}
                                                        disabled={inclusionListsSelected.includes(file.code)}/>
                                        <ListItemText
                                            style={{color: "#7587A3", paddingLeft: "5px", overflowX: "hidden"}}
                                            primary={file.name}
                                            title={inclusionListsSelected.includes(file.code) ? 'This list is used as inclusion list, you can\'t select it' : file.description}/>
                                    </MenuItem>
                                </>
                            );
                        })}
                        {isBrandAdmin && (
                            <Box textAlign="center" mt={1}>
                                <Button disabled={props.disabled} onClick={handleOpenExclusionLists} variant="outlined"
                                        size="small">
                                    Upload an exclusion list
                                </Button>
                            </Box>
                        )}
                    </PopupListContainer>
                </ClickAwayListener>
            </PopperContainer>
        </>
    );
}) as CompoundedComponent;

const PopupListContainer = styled(Box)((props) => ({
    border: "1px solid rgba(27,31,35,.15)",
    boxShadow: "0px 0px 6px rgba(196, 211, 241, 0.85)",
    borderRadius: 10,
    backgroundColor: "white",
    maxHeight: "100%",
    overflow: "auto",
    minWidth: "250px",
    minHeight: "128px",
}));

const Divide = styled(Box)({
    border: "1px solid #E4EAF0",
    margin: "0 24px",
});

const PopperContainer = styled(Popper)({
    /*margin   : '1rem',
    maxHeight: 'calc(100% - 2rem)',
    overflow : 'hidden',
    minWidth: '100%',*/
    zIndex: 1000,
});

PopupSelectList.Options = () => {
    return (
        <MenuItem onClick={() => {
        }}>
            <StyledCheckbox checked={true}/>
            &nbsp;&nbsp;
            <ListItemText primary={"realName"}/>
        </MenuItem>
    );
};
