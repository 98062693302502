import { Paper, Slide, Typography, styled } from "@mui/material";
import { PageId } from "application/pages/pages.config";
import { useOpenDialogWindow } from "components/live/DialogWindow";
import { MainFrame } from "components/templates/MainFrame";
import { useAccountTracker } from "pages/Agencies/useAccountTracker";
import { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import { useShallow } from "zustand/react/shallow";
import { ApprovalStep } from "./components/ApprovalStep";
import { DspSelectionStep } from "./components/DspSelectionStep";
import { EnablementOptionsStep } from "./components/EnablementOptionsStep";
import { EnablementStepper } from "./components/EnablementStepper";
import { FinalStep } from "./components/FinalStep";
import { PaymentStep } from "./components/PaymentStep";
import { SummaryStep } from "./components/SummaryStep";
import { useAudienceEnablementStore } from "./store/AudienceEnablement.store";

export const AudienceEnablement = () => {
  const { audienceId } = useParams<{ audienceId: string }>() as { audienceId: number };

  const { t } = useTranslation();
  const dialogWindow = useOpenDialogWindow();

  const loading = useAudienceEnablementStore((state) => state.loading);
  const loadingError = useAudienceEnablementStore((state) => state.loadingError);
  const actions = useAudienceEnablementStore((state) => state.actions);
  const audienceData = useAudienceEnablementStore(useShallow((state) => state.audienceData));
  const currentStep = useAudienceEnablementStore((state) => state.currentStep);

  const tracker = useAccountTracker(audienceData?.account?.id);
  const scrollRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    //close popup when comming from another page
    dialogWindow.close("AudiencePreviewPopup");
  }, []);

  useEffect(() => {
    actions.init(audienceId);
    return () => {
      //reset store on exit
      actions.reset();
    };
  }, [audienceId]);

  useEffect(() => {
    scrollRef?.current?.scrollTo({ top: 0 });
  }, [currentStep]);

  return (
    <MainFrame
      restrict404={loadingError}
      breadCrumbProps={{ crumbTitle: `Enable your audience`, params: { id: audienceId.toString() } }}
      headerProps={{
        headerTitle: (
          <Typography variant={"h4"}>
            {t("audience.activation.enableAudience")} {audienceData ? `[${audienceData?.id}] - ${audienceData?.name}` : ""}
          </Typography>
        ),
      }}
      frameId={PageId.audienceEnablement}
      loading={loading}
    >
      <AudienceEnablementWrap ref={scrollRef}>
        <EnablementStepper />

        {currentStep === "DspSelection" && <StepWrap children={<DspSelectionStep />} />}
        {currentStep === "EnablementOptions" && <StepWrap children={<EnablementOptionsStep />} />}
        {currentStep === "Payment" && <StepWrap children={<PaymentStep />} />}
        {currentStep === "Approval" && <StepWrap children={<ApprovalStep />} />}
        {currentStep === "Summary" && <StepWrap children={<SummaryStep />} />}
        {currentStep === "Final" && <StepWrap children={<FinalStep />} />}
      </AudienceEnablementWrap>
    </MainFrame>
  );
};

const StepWrap = ({ children }: { children: JSX.Element; direction?: "left" | "right" }) => {
  return (
    <Slide direction="left" in={true} mountOnEnter unmountOnExit>
      <Paper
        elevation={4}
        sx={{ maxWidth: "1200px", margin: "auto", marginTop: "2rem", backgroundColor: "white", borderRadius: "7px", padding: "2rem" }}
      >
        {" "}
        {children}
      </Paper>
    </Slide>
  );
};

export const AudienceEnablementWrap = styled("div")(() => ({
  overflowY: "auto",
  overflowX: "hidden",
  height: "100%",
  padding: "2rem",
  position: "relative",
}));

export const StepTitle = styled(Typography)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  fontSize: "1.5rem",
  marginBottom: "0.5rem",
}));

export const StepDescription = styled(Typography)(() => ({
  marginBottom: "1.5rem",
}));
