import {create} from 'zustand';
import {persist} from 'zustand/middleware';
import {IAccounts} from '../application/entities/dataTypes/accounts';
import { mountStoreDevtool } from "simple-zustand-devtools";

type useUiStoreType = {
  mode: 'light' | 'dark',
  activeFrame: string
  activeMenuButton: string
  accountMode: 'BRAND' | 'COMPANY' | string | undefined
  currentBrand: IAccounts | undefined
  currentCompany: IAccounts | undefined
  isAdminPage: boolean
}

export const useUiStore = create<useUiStoreType>()(persist((set, get) => ({
  mode            : 'light',
  activeFrame     : '',
  activeMenuButton: '',
  accountMode     : undefined,
  currentBrand    : {} as IAccounts,
  currentCompany  : {} as IAccounts,
  isAdminPage     : false,
}), {
  name: 'useUiStore'
}));


//Display debug in React Dev Tools
if (process.env.NODE_ENV === "development") {
  mountStoreDevtool("useUiStore", useUiStore);
}