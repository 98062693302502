import { TIcon } from "./icons";

/** List of the provider's icons **/
export const providerIconList: Record<string, TIcon> = {
  amazonads: "Amazon",
  crimtan: "Crimtan",
  dv360: "DV360",
  facebook: "Meta",
  googleads: "GAds",
  jamloop: "Jamloop",
  jamloop_all: "Jamloop",
  jamloop_hem: "Jamloop",
  liveramp: "Liveramp",
  liveramp_facebook: "Liveramp",
  liveramp_amazonads: "Liveramp",
  liveramp_dv360: "Liveramp",
  liveramp_stackadapt: "Liveramp",
  liveramp_thetradedesk: "Liveramp",
  liveramp_crimtan: "Liveramp",
  lotame: "Lotame",
  lotame_dv360: "Lotame",
  magnite: "Magnite",
  magnite_crimtan: "Magnite",
  magnite_amazon: "Magnite",
  magnite_dv360: "Magnite",
  magnite_stackadapt: "Magnite",
  magnite_thetradedesk: "Magnite",
  mediagrid: "Mediagrid",
  pubmatic_crimtan: "Crimtan",
  pubmatic_dv360: "Pubmatic",
  pubmatic_quantcast: "Pubmatic",
  pubmatic_rtbhouse: "RtbHouse",
  pubmatic_stackadapt: "Pubmatic",
  pubmatic_thetradedesk: "Pubmatic",
  pubmatic_xandr: "Xandr",
  quantcast: "Quantcast",
  rtbhouse: "RtbHouse",
  stackadapt: "StackAdapt",
  thetradedesk: "TheTradeDesk",
  xandr: "Xandr",
  yahoo: "Yahoo",
  beeswax: "Beeswax",
  hawk: "Hawk",
  adform: "AdForm",
};
