import {useAccountQuery} from "../../components/hooks/data/useAccountQuery";
import {IAccounts} from "../../application/entities/dataTypes/accounts";
import {FormEditBrand} from "../../components/brands/FormEditBrand";
import React, {useEffect} from "react";
import {CPMManagement} from "../../components/brands/CPMManagement";
import {Box, Paper, styled} from "@mui/material";
import {objectApi} from "../../application/entities/dataApi";

const flagApi = new objectApi.accounts();

export const BrandSettings = ({brandId}: { brandId: number }) => {

    const accountQuery = useAccountQuery(brandId);
    const accountData: IAccounts = accountQuery?.data ?? {};


    const [featFlags, setFeatFlags] = React.useState<Record<string, boolean>>({})


    console.log(featFlags)

    useEffect(() => {
        flagApi.getMyFeatureFlags(brandId).then((res) => {
            setFeatFlags(res?.data?.featureFlags ?? {})
        });
    }, [brandId]);


    return (
        <Box sx={{width: "700px"}}>
            <ItemBox>
                <FormEditBrand brandId={brandId} accountData={accountData}/>
            </ItemBox>
            {featFlags?.feature_service_fee && <CPMManagement brandId={brandId}/>}
        </Box>
    )
}

const ItemBox = styled(Paper)(() => ({
    padding: "1rem",
    paddingTop: 0,
    background: "#fff",
    borderRadius: "8px",
    overflowY: "auto",
    marginBottom: "2rem"
}));